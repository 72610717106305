function toPLN(price) {
    return price.toLocaleString('PL', {
        style: 'currency',
        currency: 'PLN',
        minimumFractionDigits: 2
    });
}
function sidebar_toggle() {
    $('#header > .nav').removeClass('open');
    $('#sidebar-toggle').toggleClass('toggled');
    $('#main-sidebar').toggleClass('toggled');
    $('#sidebar-bg').toggleClass('toggled');
}
function sidebar_hide() {
    $('#sidebar-toggle').removeClass('toggled');
    $('#main-sidebar').removeClass('toggled');
    $('#sidebar-bg').removeClass('toggled');
}
jQuery(function () {
    $('form input[type=number]').spinner({
        icons: {down: "icon-down", up: "icon-up"}
    });
    $('form select:not(.hidden)').select2({
        minimumResultsForSearch: Infinity,
        language: "pl",
        width: 'auto'
    });
    $('#sort').find('select').select2({
        minimumResultsForSearch: Infinity,
        language: "pl"
    });
    $('.select-search').select2({
        placeholder: '',
        language: "pl",
        allowClear: true,
        width: 'auto'
    });
    $('input.date').datetimepicker({
        locale: 'pl',
        format: 'DD.MM.YYYY'
    });
    $('input.date-time').datetimepicker({
        locale: 'pl'
    });
    var lightbox_gallery_options = {
        captions: false,
        showCounter: false,
        alertError: false,
    };
    $('.lightbox-gallery').each(function () {
        $('.lightbox', this).simpleLightbox(lightbox_gallery_options);
    });
    $('.lightbox-gallery-single a').simpleLightbox(lightbox_gallery_options);
    var yturl = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([\w\-]{10,12})(?:&feature=related)?(?:[\w\-]{0})?/g;
    var ytplayer = '<iframe width="719" height="404" src="http://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>';
    $('.youtube-linkable-content').each(function () {
        var content = $("<div/>").html(this.textContent).text().replace(yturl, ytplayer);
        this.innerHTML = anchorme(content, {
            attributes: {
                "target": "_blank",
            },
            ips: false,
            html: true,
            email: false,
        });
    });
    $('.linkable-content').each(function () {
        this.innerHTML = anchorme($("<div/>").html(this.textContent).text(), {
            attributes: {
                "target": "_blank",
            },
            ips: false,
            html: false,
            email: false,
        });
    });
    $('#menu-toggle').click(sidebar_hide);
    $('#sidebar-toggle').click(sidebar_toggle);
    $('#sidebar-bg').click(sidebar_toggle);
    $(window).on('load resize', function () {
        if ($(window).width() < 992 && $(window).width() > 767) {
            var $header_height = $('#header').height();
            $('#main-sidebar').css('top', $header_height);
            $('#main-container').css('margin-top', ($header_height + 20) + "px");
        }
        else {
            $('#main-sidebar').css('top', '');
            $('#main-container').css('margin-top', '');
        }
    });
    $('.search-box__select > label').click(function () {
        $(this).siblings().removeClass('checked');
        $(this).addClass('checked');
    });
    $('a.watch_intro').click(function () {
        $('#intro-box').hide();
        $('#intro-video-container').show();
        document.getElementById('intro-video').play();
    });
});